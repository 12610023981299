'use client';

import Link from 'next/link';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import authService from '@/services/auth.service';
import toast from 'react-hot-toast';
import { useAuth } from '@/context/authContext';
import { useRouter } from 'next/router';

const MainDemoBanner = () => {
   const { user, authenticated } = useAuth();
   const { setToken } = useAuth();
   const router = useRouter();
   const handleLogin = async values => {
      try {
         const data = await authService.login({
            phone: values.phone,
            password: values.password
         });
         if (data) {
            toast.success('Login success');
            localStorage.setItem('token', data.token);
            setToken(data.token);
            router.push('/dashboard');
         }
      } catch (error) {
         toast.error(error.response.data.message);
      }
   };

   const formik = useFormik({
      initialValues: {
         phone: '',
         password: ''
      },
      validationSchema: Yup.object().shape({
         phone: Yup.string()
            .required()
            .matches(/^[0-9]+$/, 'Phone number must contain only numbers'),
         password: Yup.string().required('Password is required')
      }),
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
      onSubmit: values => handleLogin(values)
   });

   const handleLogout = async () => {
      await authService.logout();
   };

   return (
      <>
         <div className="container-fluid">
            <div className="content">
               <div
                  className="mt-5 rbt-contact-form contact-form-style-1 max-width-auto"
                  style={{
                     minWidth: '30%'
                  }}>
                  {!authenticated ? (
                     <>
                        <h3 className="title">Login</h3>
                        <form
                           className="max-width-auto"
                           onSubmit={formik.handleSubmit}>
                           <div className="form-group">
                              <input
                                 name="phone"
                                 id="phone"
                                 type="text"
                                 placeholder="Phone number *"
                                 {...formik.getFieldProps('phone')}
                              />
                              {errorComponent(formik, 'phone')}
                              <span className="focus-border"></span>
                           </div>
                           <div className="form-group">
                              <input
                                 name="password"
                                 id="password"
                                 type="password"
                                 placeholder="Password *"
                                 {...formik.getFieldProps('password')}
                              />
                              {errorComponent(formik, 'password')}
                              <span className="focus-border"></span>
                           </div>

                           <div className="row mb--30">
                              <div className="col">
                                 <div className="rbt-checkbox">
                                    <input
                                       type="checkbox"
                                       id="rememberme"
                                       name="rememberme"
                                    />
                                    <label htmlFor="rememberme">
                                       Remember me
                                    </label>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="rbt-lost-password text-end">
                                    <button
                                       className="rbt-btn-link border-0 bg-transparent"
                                       onClick={() =>
                                          window.open(
                                             'https://api.whatsapp.com/send?phone=94718785378&text=Hi',
                                             '_blank'
                                          )
                                       }>
                                       Forgot your password?
                                    </button>
                                 </div>
                              </div>
                           </div>

                           <div className="form-submit-group">
                              <button
                                 type="submit"
                                 className="border rbt-btn btn-md border-primary hover-icon-reverse w-100">
                                 <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Log In</span>
                                    <span className="btn-icon">
                                       <i className="feather-arrow-right"></i>
                                    </span>
                                    <span className="btn-icon">
                                       <i className="feather-arrow-right"></i>
                                    </span>
                                 </span>
                              </button>
                           </div>
                           <div>
                              <div className="mt-5 text-center rbt-lost-password">
                                 <Link
                                    className="rbt-btn-link"
                                    href="/register">
                                    {`Don't have an account ? Sign Up`}
                                 </Link>
                              </div>
                           </div>
                        </form>
                     </>
                  ) : (
                     <>
                        <div>
                           <h3 className="title">
                              Welcome {user?.firstName} !
                           </h3>
                           <p className="description">
                              You are already logged in <br />
                              Click the button below to go to the dashboard
                           </p>
                           <div className="form-submit-group">
                              <Link
                                 href="/dashboard"
                                 className="border rbt-btn btn-md hover-icon-reverse w-100 border-primary">
                                 <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Dashboard</span>
                                    <span className="btn-icon">
                                       <i className="feather-arrow-right"></i>
                                    </span>
                                    <span className="btn-icon">
                                       <i className="feather-arrow-right"></i>
                                    </span>
                                 </span>
                              </Link>
                           </div>
                        </div>
                     </>
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default MainDemoBanner;

const errorComponent = (formik, name) =>
   formik.touched[name] && formik.errors[name] ? (
      <div
         className="text-danger"
         style={{
            fontSize: '12px'
         }}>
         {formik.errors[name]}
      </div>
   ) : null;
